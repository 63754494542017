const dbAddOns = [
  {
    id: "DtwHQHpkmPP",
    name: "Gatorade",
    price: 30.0,
    description: "24-pack, 20 oz bottles, various flavors",
    imageSrc:
      "https://tourney-tents-web-images.s3.amazonaws.com/addOns-gatoradeCase.png",
    quantity: 0,
    stillOffered: true,
    availability: "ALA_CARTE",
  },
  {
    id: "dzMdFmPWPDz",
    name: "Water",
    price: 20.0,
    description: "48-pack, 16.9 oz bottles",
    imageSrc:
      "https://tourney-tents-web-images.s3.amazonaws.com/addOns-bottledWaterCase.png",
    quantity: 0,
    stillOffered: true,
    availability: "ALA_CARTE",
  },
  {
    id: "BHGcnckTrLp",
    name: "Bagels",
    price: 70.0,
    description:
      "20 plain, sliced bagels with three 1/2 lb cream cheese containers",
    imageSrc:
      "https://tourney-tents-web-images.s3.amazonaws.com/addOns-bagelsCreamCheese.png",
    quantity: 0,
    stillOffered: true,
    availability: "ALA_CARTE",
  },
];

const dbPackages = [
  {
    name: "Standard Package",
    id: "WjKmpGRTqTP",
    href: "#",
    price: 1000,
    discountText: "",
    description:
      "Our premier concierge package. Everything you need for a successful tournament.",
    features: [
      "1 tent",
      "1 table",
      "2x 6-seat benches",
      "1 cooler with ice",
      "Set up / breakdown",
    ],
    stillOffered: true,
    availability: "BUNDLE",
  },
  {
    name: "Double Package",
    id: "wqCMPGG7Q6b",
    href: "#",
    price: 1850,
    discountText: "Save more on your second set",
    description: "Two times the fun! Save more on your second package.",
    features: [
      "2 tents",
      "2 tables",
      "4x 6-seat benches",
      "2 coolers with ice",
      "Set up / breakdown",
    ],
    stillOffered: true,
    availability: "BUNDLE",
  },
];

const dbTournaments = [
  {
    id: "ysFtgvQDsSu",
    name: "NHSLS Varsity (RED)",
    city: "Columbia",
    state: "MD",
    dateString: "November 23, 2024",
    numberOfDays: 1,
    sport: "LACROSSE",
    gender: "BOYS",
    link: "https://nhsls.com/boys/",
    imageAlt: "NHSLS Varsity (RED)",
    imageSrc:
      "https://tourney-tents-web-images.s3.amazonaws.com/logos-nhslsLogo.png",
    startTimestamp: "2024-11-23T13:00:00.000Z",
    closeRegistrationTimestamp: "2024-11-21T13:00:00.000Z",
    singlePrice: 1000,
    doublePrice: 1850,
    localSalesTax: 0.06,
  },
  {
    id: "XfrjDRJTVfA",
    name: "NHSLS Varsity (WHITE)",
    city: "Columbia",
    state: "MD",
    dateString: "November 24, 2024",
    numberOfDays: 1,
    sport: "LACROSSE",
    gender: "BOYS",
    link: "https://nhsls.com/boys/",
    imageAlt: "NHSLS Varsity (WHITE)",
    imageSrc:
      "https://tourney-tents-web-images.s3.amazonaws.com/logos-nhslsLogo.png",
    startTimestamp: "2024-11-24T13:00:00.000Z",
    closeRegistrationTimestamp: "2024-11-22T13:00:00.000Z",
    singlePrice: 1000,
    doublePrice: 1850,
    localSalesTax: 0.06,
  },
  {
    id: "1Jdt3X5Gita",
    name: "NHSLS Junior Varsity",
    city: "Columbia",
    state: "MD",
    dateString: "November 23, 2024",
    numberOfDays: 1,
    sport: "LACROSSE",
    gender: "BOYS",
    link: "https://nhsls.com/boys/",
    imageAlt: "NHSLS Junior Varsity",
    imageSrc:
      "https://tourney-tents-web-images.s3.amazonaws.com/logos-nhslsLogo.png",
    startTimestamp: "2024-11-23T13:00:00.000Z",
    closeRegistrationTimestamp: "2024-11-21T13:00:00.000Z",
    singlePrice: 1000,
    doublePrice: 1850,
    localSalesTax: 0.06,
  },
  {
    id: "BJvCfkbnUu7",
    name: "HoganLax Fall Brawl",
    city: "Annapolis",
    state: "MD",
    dateString: "November 3, 2024",
    numberOfDays: 1,
    sport: "LACROSSE",
    gender: "BOYS",
    link: "https://hoganlax.com/fallbrawl/",
    imageAlt: "HoganLax Fall Brawl",
    imageSrc:
      "https://tourney-tents-web-images.s3.amazonaws.com/logos-hoganLaxFallBrawlLogo.png",
    startTimestamp: "2024-11-03T13:00:00.000Z",
    closeRegistrationTimestamp: "2024-11-01T13:00:00.000Z",
    singlePrice: 600,
    doublePrice: 1100,
    localSalesTax: 0.06,
  },
  {
    id: "XoegrKU35AG",
    name: "Chesapeake Invitational",
    city: "Baltimore",
    state: "MD",
    dateString: "November 9-10, 2024",
    numberOfDays: 2,
    sport: "LACROSSE",
    gender: "GIRLS",
    link: "https://lacrosse.sincsports.com/TTContent.aspx?tid=CHESAPEA&tab=1",
    imageAlt: "Chesapeake Invitational",
    imageSrc:
      "https://tourney-tents-web-images.s3.amazonaws.com/logos-chesapeakeInvitationalLogo.png",
    startTimestamp: "2024-11-09T13:00:00.000Z",
    closeRegistrationTimestamp: "2024-11-07T13:00:00.000Z",
    singlePrice: 1000,
    doublePrice: 1850,
    localSalesTax: 0.06,
  },
  {
    id: "VDEL8WJ3n2d",
    name: "Mid-Atlantic Showcase",
    city: "Baltimore",
    state: "MD",
    dateString: "November 16-17, 2024",
    numberOfDays: 2,
    sport: "LACROSSE",
    gender: "GIRLS",
    link: "https://lacrosse.sincsports.com/details.aspx?tid=MIDATL&tab=1",
    imageAlt: "Mid-Atlantic Showcase",
    imageSrc:
      "https://tourney-tents-web-images.s3.amazonaws.com/logos-midAtlanticShowcaseLogo.png",
    startTimestamp: "2024-11-16T13:00:00.000Z",
    closeRegistrationTimestamp: "2024-11-14T13:00:00.000Z",
    singlePrice: 1000,
    doublePrice: 1850,
    localSalesTax: 0.06,
  },
  {
    id: "OTvUcJkfwCI",
    name: "President's Cup",
    city: "Bradenton",
    state: "FL",
    dateString: "November 22-24, 2024",
    numberOfDays: 3,
    sport: "LACROSSE",
    gender: "GIRLS",
    link: "https://iwlcarecruiting.com/tournaments/iwlca-presidents-cup/",
    imageAlt: "President's Cup",
    imageSrc:
      "https://tourney-tents-web-images.s3.amazonaws.com/logos-presidentsCupLogo.png",
    startTimestamp: "2024-11-22T13:00:00.000Z",
    closeRegistrationTimestamp: "2024-11-08T13:00:00.000Z",
    singlePrice: 1500,
    doublePrice: 2700,
    localSalesTax: 0.07,
  },
  {
    id: "kNHFLMCW9WF",
    name: "IWLCA Debut",
    city: "Tampa",
    state: "FL",
    dateString: "November 23-24, 2024",
    numberOfDays: 2,
    sport: "LACROSSE",
    gender: "GIRLS",
    link: "https://iwlcarecruiting.com/tournaments/iwlca-debut/",
    imageAlt: "IWLCA Debut",
    imageSrc:
      "https://tourney-tents-web-images.s3.amazonaws.com/logos-iwclaDebutLogo.png",
    startTimestamp: "2024-11-23T13:00:00.000Z",
    closeRegistrationTimestamp: "2024-11-09T13:00:00.000Z",
    singlePrice: 1000,
    doublePrice: 1850,
    localSalesTax: 0.07,
  },
];

const dbEventProductAvailability = [
  // Iterate over each dbTournament
  ...dbTournaments.flatMap((dbTournament) => [
    // Iterate over each package to add them to each dbTournament
    ...dbPackages.map((dbPackage) => ({
      id: `${dbTournament.id}-${dbPackage.id}`,
      eventId: dbTournament.id,
      productId: dbPackage.id,
      isAvailable: true,
    })),
    // Iterate over each add-on to add them to each dbTournament
    ...dbAddOns.map((addOn) => ({
      id: `${dbTournament.id}-${addOn.id}`,
      eventId: dbTournament.id,
      productId: addOn.id,
      isAvailable:
        !["OTvUcJkfwCI", "kNHFLMCW9WF"].includes(dbTournament.id) ||
        addOn.id !== "BHGcnckTrLp", // Bagels are not available at President's Cup or the new event
    })),
  ]),
];

const dbDiscounts = [
  {
    id: "BD6CkWgJd8N",
    code: "EARLYBIRDFALL",
    description: "Get 20% off your entire order, now through 10/15!",
    type: "PERCENTAGE",
    value: 0.2,
    expirationDate: "2024-10-15T23:59:59Z",
    usageLimit: null,
    createdAt: "2024-09-24T00:00:00Z",
    updatedAt: "2024-09-24T00:00:00Z",
  },
  {
    id: "CzKD8FwJJqB",
    code: "FIELDREADY15",
    description: "Save 15% off on orders through 11/15.",
    type: "PERCENTAGE",
    value: 0.15,
    expirationDate: "2024-11-15T23:59:59Z",
    usageLimit: 100,
    createdAt: "2024-09-24T00:00:00Z",
    updatedAt: "2024-09-24T00:00:00Z",
  },
];

export {
  dbAddOns,
  dbPackages,
  dbTournaments,
  dbEventProductAvailability,
  dbDiscounts,
};
